import React from "react";

import { Container, Heading, Text, VStack } from "@chakra-ui/react";

function Features() {
  return (
    <Container size="md" pt={{ base: "5rem", md: "8.125rem" }}>
      <VStack spacing={{ base: "3rem", md: "4.25rem" }}>
        {/* Heading */}
        <VStack spacing="1.25rem">
          <VStack spacing="0.5rem">
            <Text fontWeight="700" color="red.400" textAlign="center">
              About Us
            </Text>
            <Heading as="h2" size="lg" textAlign="center">
						MakeDeals.ie is built on the vision of strengthening local connections.
            </Heading>
          </VStack>
          <Text color="muted" maxW="70rem"  lineHeight="2rem">
							We are here to establish a trusted digital presence while staying committed to transparency, reliability, and future growth.
          </Text>
        </VStack>
      </VStack>
    </Container>
  );
}

export default Features;
